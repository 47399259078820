.headshot {
  border-radius: 50%;
  position: relative;
  width: 500px;
  margin-right: 5%;
}

.total {
    margin-left: 16%;
    margin-top: 5%;
    overflow: hidden;
    -webkit-transition: .5s ease;
    transition: .5s ease;
    margin-bottom: 200px;

}

.all {
    position: relative;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.row {
    display: inline-flex;
}

.photos {

}

.column, .columns {
    width: 100%;
    float: left;
    box-sizing: border-box;
}

@media (min-width: 550px) {
.one-third.column {
    width: 34%;
}
.two-thirds.column {
    width: 65%;
}
.column, .columns {
    margin-left: 4%;
}

}


 @media (max-width: 680px) {
    .row {
    display: block;
}
 }

.container:after, .row:after, .u-cf {
    content: '';
    display: table;
    clear: both;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.image {
    position: relative;
}


