 header {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


.icecream {
    position: relative;
    width: 100%;
    max-width: 700px;
    margin-left: 30%;
    padding: 0 20px;
    box-sizing: border-box;
}

.page{

    position: relative;
    overflow: hidden;
}

h1 {
    font-family: Karla, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.75);
    line-height: 32px;
    margin-top: 40px;
    padding-top: 5px;
}

h1 i {
    color: rgba(0, 0, 0, 0.25);
    font-family: Karla, Helvetica, sans-serif;
    font-weight: bold;
}

.logo {
    width: 65px;
    height: 65px;
    float: left;
    margin-right: 20px;
    border-radius: 30%;
}
