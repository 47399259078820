.site-text {
    font-family: Karla, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 29px;
    line-height: 40px;
    color: white;
    text-align: center;
    padding: 90px 10px 75px 10px;
    margin: auto;
    box-sizing: border-box;
}

.site-intro {
    position: relative;
    z-index: 2;
    display: table;
    width: 100%;
}

.site-intro, .site-menu, .site-menu:after {
    background: #FFB886;
    background: -webkit-linear-gradient(left, #28BBEB, #FF637E, #FFE226, #1CD5AF, #28BBEB);
    background: linear-gradient(to right, #28BBEB, #FF637E, #FFE226, #1CD5AF, #28BBEB);
    background-size: 400% 100%;
    background-position: 33.3% 0;
    -webkit-transition: background-position .5s ease;
    transition: background-position .5s ease;
}

.site-menu-underline {
  left: 365.656px;
  width: 40.5156px;
}

.site-menu {
    padding-top: 10px;
    position: relative;
    cursor: default;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    text-align: center;
}

ul {
    list-style: none;
}

.site-nav {
    position: relative;
    position: -webkit-sticky;
    position: fixed;
    padding-top: 10px;
    top: 0 !important;
    z-index: 1;
    display: table;
    width: 100%;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    margin-top: -30px;
    height: 10px;
}

.site-menu-item {
    display: inline-block;
    margin: 0 2em;
    font-family: Karla, Helvetica, sans-serif;
    font-size: 20px;
    color: #FFFFFF !important;
    letter-spacing: 0.2px;
    line-height: 16px;
    font-weight: bold;
    padding: 20px;
}

.site-menu.site-menu-animated .site-menu-item a {
    border-bottom-color: transparent;
}

.site-menu-item a {
    display: inline-block;
    color: #FFFFFF !important;
    padding-bottom: 10px;
    border-bottom: 3px solid transparent;
    -webkit-transition: color .5s ease;
    transition: color .5s ease;
}

a {
    text-decoration: none;
    transition: 200ms;
    transition-timing-function: ease-in-out;
    -webkit-transition: 200ms;
    -webkit-transition-timing-function: ease-in-out;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

*, *:before, *:after {
    box-sizing: inherit;
}

ul, menu, dir {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

a:hover {
  color: gray;
}

@media only screen and (max-width: 680px) {
    .site-intro, .site-text{
     width: 100%;
     height: 10%;
}

.site-menu.site-menu-animated {
  font-size: 2%;
  padding-top: 20%;
  display: block;
}

.site-nav {
    position: relative;
    position: -webkit-sticky;
    position: fixed;
    }

   .map {
    width: 50%;
    height: 50%;
   }

}


